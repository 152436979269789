<script setup lang="ts">
import { computed } from "vue"
import type { RouteLocationRaw } from "vue-router"
import { useRoute } from "#imports"

const props = defineProps<{
  pathRoot: string
  to: string | RouteLocationRaw
  external?: boolean
}>()

const current = computed(() => props.pathRoot === currentPathRoot.value)

const currentPathRoot = computed(() => {
  const path = useRoute().path
  if (!path) return ""
  return path.split("/")[1]
})
</script>

<template>
  <NuxtLink
    :to="to"
    :class="[
      current ? 'text-indigo-600' : 'text-slate-600',
      'bg-white h-8 px-4 border border-slate-200 rounded flex w-full justify-center items-center text-xs font-semibold transition-all duration-300 hover:text-indigo-500 sm:w-auto sm:font-medium sm:text-sm sm:border-none sm:hover:text-indigo-600 sm:hover:bg-indigo-25',
    ]"
    :aria-current="current ? 'page' : undefined"
    :target="external ? '_blank' : undefined"
  >
    <slot />
  </NuxtLink>
</template>
